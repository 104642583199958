@media (min-width : 1200px) {
    /* banner */
    #sequence-theme { height: 650px; }
    #sequence-theme #sequence {
        height: 100%;
    }
    #sequence h2, #sequence .h2 {
        color: #3e3d40;
    }
    #sequence h3, #sequence .h3 {
        color: #3e3d40;
    }
    /* banner pages */
    #sequence-theme.sequence-pages { height: 500px; margin-top: 10px; margin-bottom: -61px; }
    #sequence-theme.sequence-pages .animate-in .title-left-to-right-animate {
        left: 0;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        width: 50%;
    }
    #sequence-theme.sequence-pages .animate-in .right-img-from-top-out-to-right {
        top: 120px;
    }
    /* end banner */
}

@media (max-width : 1200px) {
    #sequence-theme { height: 650px; }
    #sequence-theme #sequence {
        height: 100%;
    }
    #sequence h2, #sequence .h2 {
        color: #3e3d40;
    }
    #sequence h3, #sequence .h3 {
        color: #3e3d40;
    }
    /* banner pages */
    #sequence-theme.sequence-pages { height: 520px; margin-top: 10px; margin-bottom: -61px; }
    #sequence-theme.sequence-pages .animate-in .title-left-to-right-animate {
        left: 0;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        width: 50%;
    }
    #sequence-theme.sequence-pages .animate-in .right-img-from-top-out-to-right {
        top: 120px;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        top: 120px;
    }
    /* end banner */
}

/* Medium Devices, Desktops */
@media (max-width : 992px) {
    #sequence-theme { height: 560px; }
    #sequence-theme #sequence {
        height: 100%;
    }
    #sequence h2, #sequence .h2 {
        color: #fff;
    }
    #sequence h3, #sequence .h3 {
        color: #fff;
    }
    #sequence-theme .animate-in .title-from-top-animate {
        top: 150px;
    }
    #sequence-theme .animate-in .img-from-top {
        top: 250px;
    }
    /* banner pages */
    #sequence-theme.sequence-pages h2, #sequence-theme.sequence-pages .h2 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages h3, #sequence-theme.sequence-pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages { height: 500px; margin-top: 10px; margin-bottom: -61px; }
    #sequence-theme.sequence-pages .animate-in .title-left-to-right-animate {
        left: 0;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        width: 50%;
    }
    #sequence-theme.sequence-pages .animate-in .right-img-from-top-out-to-right {
        top: 80px;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        top: 80px;
    }
    /* end banner */
}

/* Small Devices, Tablets */
@media (max-width : 768px) {
    #sequence-theme { height: 480px; }
    #sequence-theme #sequence {
        height: 100%;
    }
    #sequence h2, #sequence .h2 {
        color: #fff;
    }
    #sequence h3, #sequence .h3 {
        color: #fff;
    }
    #sequence-pages h2, #sequence-pages .h2 {
        color: #3e3d40;
    }
    #sequence-pages h3, #sequence-pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme .animate-in .title-from-top-animate {
        top: 80px;
    }
    #sequence-theme .animate-in .img-from-top {
        top: 200px;
    }
    /* banner pages */
    #sequence-theme.sequence-pages h2, #sequence-theme.sequence-pages .h2 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages h3, #sequence-theme.sequence-pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages { height: 760px; margin-top: 10px; margin-bottom: -61px; }
    #sequence-theme.sequence-pages .animate-in .title-left-to-right-animate {
        left: 0;
        width: 100%;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        width: 50%;
    }
    #sequence-theme.sequence-pages .animate-in .right-img-from-top-out-to-right {
        top: 480px;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        top: 80px;
    }
    /* end banner */
}

/* Extra Small Devices, Phones */
@media (max-width : 480px) {
    #sequence-theme { height: 370px; }
    #sequence-theme #sequence {
        height: 100%;
    }
    #sequence h2, #sequence .h2 {
        color: #fff;
    }
    #sequence h3, #sequence .h3 {
        color: #fff;
    }
    #sequence.pages h2, #sequence.pages .h2 {
        color: #3e3d40;
    }
    #sequence.pages h3, #sequence.pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme .animate-in .title-from-top-animate {
        top: 80px;
    }
    #sequence-theme .animate-in .img-from-top {
        top: 200px;
    }
    /* banner pages */
    #sequence-theme.sequence-pages h2, #sequence-theme.sequence-pages .h2 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages h3, #sequence-theme.sequence-pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages { height: 740px; margin-top: 10px; margin-bottom: -61px; }
    #sequence-theme.sequence-pages .animate-in .title-left-to-right-animate {
        left: 0;
        width: 100%;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        width: 50%;
    }
    #sequence-theme.sequence-pages .animate-in .right-img-from-top-out-to-right {
        top: 380px;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        top: 80px;
    }
    /* end banner */
}

/* Custom, iPhone Retina */
@media (max-width : 320px) {
    #sequence-theme { height: 350px; }
    #sequence-theme #sequence {
        height: 100%;
    }
    #sequence h2, #sequence .h2 {
        color: #fff;
    }
    #sequence h3, #sequence .h3 {
        color: #fff;
    }
    #sequence.pages h2, #sequence.pages .h2 {
        color: #3e3d40;
    }
    #sequence.pages h3, #sequence.pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme .animate-in .title-from-top-animate {
        top: 80px;
    }
    #sequence-theme .animate-in .img-from-top {
        top: 200px;
    }
    /* banner pages */
    #sequence-theme.sequence-pages h2, #sequence-theme.sequence-pages .h2 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages h3, #sequence-theme.sequence-pages .h3 {
        color: #3e3d40;
    }
    #sequence-theme.sequence-pages { height: 730px; margin-top: 10px; margin-bottom: -61px; }
    #sequence-theme.sequence-pages .animate-in .title-left-to-right-animate {
        left: 0;
        width: 100%;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        width: 50%;
    }
    #sequence-theme.sequence-pages .animate-in .right-img-from-top-out-to-right {
        top: 320px;
    }
    #sequence-theme.sequence-pages .title-left-to-right-animate {
        top: 80px;
    }
    /* end banner */
}

#sequence h2, #sequence .h2, #sequence h3, #sequence .h3 {
    color: #fff;
}
